import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { RichText } from 'prismic-reactjs';
import PageContainer from '../../components/pageContainer';
import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
// import Hero01 from '../components/index/01Hero';
// import OurStory02 from '../components/index/02OurStory';
// import Dishes03 from '../components/index/03Dishes';
// import Quality04 from '../components/index/04Quality';
// import GiftBanner05 from '../components/index/05GiftBanner';
// import Press06 from '../components/index/06Press';
// import MenuBanner07 from '../components/index/07MenuBanner';
// import Menu10 from "../components/index/08Location";
// import Menu10 from '../components/index/08Location';
// import GalleryBanner09 from '../components/index/09GalleryBanner';
// import Instagram10 from '../components/index/10Instagram';

const Container = styled.div`
  margin-top: 32px;

  @media (min-width: 600px) and (orientation: portrait) {
    margin-top: 64px;
  }

  @media (min-width: 900px) {
    margin-top: 64px;
  }

  @media (min-width: 1200px) {
    margin-top: 96px;
  }
`;

const MenuSection = styled.section`
  margin-top: 32px;

  @media (min-width: 600px) and (orientation: portrait) {
    margin-top: 64px;
  }

  @media (min-width: 900px) {
    margin-top: 64px;
  }

  @media (min-width: 1200px) {
    margin-top: 96px;
  }
`;
const MenuSectionTitle = styled.h2`
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  line-height: 1.2;
  color: #064f71;
  font-size: 2.2rem;

  @media (min-width: 600px) {
    font-size: 3rem;
  }

  @media (min-width: 900px) {
    font-size: 5rem;
  }

  @media (min-width: 1200px) {
    font-size: 8rem;
  }
`;
const MenuItems = styled.div`
  margin: 16px 0 0;
  display: flex;
  flex-wrap: wrap;

  //@media (min-width: 600px) {
  //  //column-count: 2;
  //  column-gap: 32px;
  //  margin-top: 32px;
  //}
  //
  //@media (min-width: 900px) {
  //  column-gap: 64px;
  //}
  //
  //@media (min-width: 1200px) {
  //  //column-count: 3;
  //}
`;
const MenuItem = styled.div`
  break-inside: avoid-column;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  width: 50%;
  padding: 16px;

  //&:first-child {
  //  margin-top: 0;
  //}

  @media (min-width: 900px) {
    padding: 32px;
    width: 33.333%;
  }

  @media (min-width: 1200px) {
    width: 25%;
  }
`;
const MenuItemLeft = styled.div`
  flex: 1;
`;
const MenuItemRight = styled.div`
  width: 64px;
  text-align: right;
`;
const MenuItemTitle = styled.h3`
  margin: 0;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  color: #064f71;
  text-align: center;

  @media (min-width: 900px) {
    font-size: 1.4rem;
  }
`;
const MenuItemPrice = styled.h3`
  margin: 0;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  color: #064f71;

  @media (min-width: 900px) {
    font-size: 1.4rem;
  }
`;
const MenuItemDescription = styled.div`
  font-family: 'Displayed', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  text-align: center;
  height: 96px;
`;

const ItemImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
`;

const ItemImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const BeveragesPage = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        prismic {
          allMenu_pages(uid: "beverages") {
            edges {
              node {
                title
                description
                sections {
                  section {
                    ... on PRISMIC_Menu_section {
                      section_title
                      _linkType
                      items {
                        description
                        name
                        price
                        item_image
                        item_imageSharp {
                          childImageSharp {
                            fluid(maxWidth: 512, maxHeight: 512) {
                              ...GatsbyImageSharpFluid_noBase64
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          allHome_pages {
            edges {
              node {
                title
                description
                seo_image
                seo_imageSharp {
                  childImageSharp {
                    fixed(width: 1024) {
                      ...GatsbyImageSharpFixed_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const home = data.prismic.allHome_pages.edges.slice(0, 1).pop();
      // if (!node) {
      //   return null;
      // }
      //
      const { seo_imageSharp } = home.node;

      const { node } = data.prismic.allMenu_pages.edges.slice(0, 1).pop();
      if (!node) {
        return null;
      }
      const { title, description, sections } = node;

      const meta = [];

      if (seo_imageSharp) {
        meta.push({
          name: `og:image`,
          content:
            data.site.siteMetadata.siteUrl +
            seo_imageSharp.childImageSharp.fixed.src,
        });
      }

      return (
        <Layout>
          <SEO
            title={title ? RichText.asText(title) : null}
            description={description ? RichText.asText(description) : null}
            meta={meta}
          />
          <Container>
            <PageContainer>
              {sections.map(({ section }, index) => {
                return (
                  <MenuSection key={index}>
                    {section.section_title && (
                      <MenuSectionTitle>
                        {RichText.asText(section.section_title)}
                      </MenuSectionTitle>
                    )}
                    {section.items && (
                      <MenuItems>
                        {section.items.map((item, index) => {
                          return (
                            <MenuItem key={index}>
                              <ItemImageContainer>
                                <ItemImage>
                                  {item.item_imageSharp && (
                                    <GatsbyImage
                                      fluid={
                                        item.item_imageSharp.childImageSharp
                                          .fluid
                                      }
                                    />
                                  )}
                                </ItemImage>
                              </ItemImageContainer>
                              <MenuItemLeft>
                                {item.name && (
                                  <MenuItemTitle>
                                    {RichText.asText(item.name)}
                                  </MenuItemTitle>
                                )}
                                {
                                  <MenuItemDescription>
                                    {item.description &&
                                      RichText.asText(item.description)}
                                  </MenuItemDescription>
                                }
                              </MenuItemLeft>
                              {/*<MenuItemRight>*/}
                              {/*  {item.price && (*/}
                              {/*    <MenuItemPrice>*/}
                              {/*      {RichText.asText(item.price)}*/}
                              {/*    </MenuItemPrice>*/}
                              {/*  )}*/}
                              {/*</MenuItemRight>*/}
                            </MenuItem>
                          );
                        })}
                      </MenuItems>
                    )}
                  </MenuSection>
                );
              })}
            </PageContainer>
          </Container>
        </Layout>
      );
    }}
  />
);

export default BeveragesPage;
